import "../css/main.css";
import "../css/fonts.css";

import React from "react";
import styled from "styled-components";
import BlogLayout from "../components/Blog/Layout";
import { withPrefix } from "gatsby";
import { P, H3, Button as ButtonText } from "../components/Typography";
import RedeemContent from "../components/Signup";
import Button from "../components/Button";
import Seo from "../components/SeoHead";

const Creators = () => {
  return (
    <Root>
      <Seo />
      <BlogLayout>
        <div
          style={{
            width: "100%",
            height: 300,
            backgroundPosition: "80% 70%",
            backgroundSize: "cover",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${withPrefix("/images/media/wallpapers/wallpaper11.jpg")})`
          }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              background: "linear-gradient(to bottom, rgba(13, 6, 30, 0) 40%, #0d061e)"
            }}
          />

          <P
            style={{
              color: "#fff",
              zIndex: 3,
              paddingTop: 40,
              fontWeight: 500,
              letterSpacing: 2,
              fontFamily: "var(--condensedFont)"
            }}
            className="header">
            CREATORS PROGRAM
          </P>
        </div>

        <div style={{ paddingTop: 40 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%"
            }}>
            <ContentContainer>
              <P style={{ marginBottom: 12, fontSize: 22 }}>
                We want to help you grow. By helping you grow your audience, Skyweaver grows as a game and as a
                community.
              </P>

              <H3 style={{ fontSize: 24, fontWeight: 800, marginTop: 40 }}>Join the Creators Program</H3>

              <P style={{ fontSize: 18, marginTop: 12 }}>
                Our Creators Program will provide you with the tools, resources and support that you need to create the
                best Skyweaver content possible.
              </P>
            </ContentContainer>

            <StyledRedeemContent creatorSignup />

            <StreamerKitContainer>
              <div style={{ flex: 1, padding: "0 20px" }}>
                <P style={{ fontWeight: 600, fontSize: 24 }}>Skyweaver Streamer Kit</P>

                <P style={{ fontSize: 18, marginTop: 4 }}>
                  Download the Streamer Kit to start streaming or making videos right now!
                </P>
              </div>

              <div className="buttonContainer">
                <StreamerKitButton
                  onClick={() => {
                    window.open("https://drive.google.com/drive/folders/1xhX_fpfoN24iOk4LrJKA1z5FzP9l1CaK", "_blank");
                  }}>
                  <ButtonText style={{ color: "#000" }}>DOWNLOAD STREAMER KIT</ButtonText>
                </StreamerKitButton>
              </div>
            </StreamerKitContainer>
          </div>

          <P />
        </div>
      </BlogLayout>
    </Root>
  );
};

const StreamerKitButton = styled(Button)``;

const StreamerKitContainer = styled.div`
  width: 70%;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #c3aced;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
    text-align: center;
    width: 90%;

    .buttonContainer {
      margin-top: 20px;
    }

    ${StreamerKitButton} {
      width: 100%;
    }

    ${StreamerKitButton} span {
      text-align: center;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const ContentContainer = styled.div`
  margin: 20px auto 0 auto;
  width: 70%;
  max-width: 800px;
  text-align: center;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

const StyledRedeemContent = styled(RedeemContent)`
  height: 100%;
  min-height: 0;
  width: 40%;
  margin: 0 auto;
  background-color: transparent;

  .background,
  .subtitle,
  .gradient,
  h2 {
    display: none;
  }

  form {
    padding: 0;
  }

  @media (max-width: 800px) {
    width: 90%;
  }
`;

const Root = styled.div`
  p.header {
    font-size: 48px !important;
    text-align: center;
  }
`;

export default Creators;
